import { Common } from '@stockbit/ui';
import { assetPrefix } from 'constants/app';
import Image from 'global/Image';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';
import clsx from 'clsx';
import { PhonePreview } from '../uikit';
import { SectionWrapper, Title, Description, StepWrapper } from './styled';

const { Flex } = Common;

function Transaction() {
  const [viewed, setViewed] = useState(false);
  const stepImages = ['x4a', 'x4b', 'x4c', 'x4d', 'x4e'];

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <SectionWrapper>
        <div className="small" style={{ position: 'relative' }}>
          <PhonePreview
            videoUrl={`${assetPrefix}/homepage/buy2.mp4`}
            fallbackImage="/images/buy2fallback.webp"
          />
        </div>
        <Flex className="big" direction="column">
          <Title className={clsx('fadeInX', viewed && 'fadeIn')}>
            Mudahnya Bertransaksi
            <br />
            dalam 3 Langkah
          </Title>
          <StepWrapper>
            {stepImages.map((image) => (
              <Image
                className={clsx('scaleUpX', viewed && 'scaleUp')}
                key={image}
                src={`images/${image}.svg`}
                alt="step"
              />
            ))}
          </StepWrapper>
          <Description>
            Didesain untuk investor millenial, kamu dapat invest di saham
            ternama dengan mudah untuk meraih mimpimu menuju kebebasan
            finansial.
          </Description>
        </Flex>
      </SectionWrapper>
    </ReactVisibilitySensor>
  );
}

export default Transaction;
